$bg: #e8eaf6;
$codebg: #c5cae9;
$fg: #000;
$standout: #42a5f5;
$fginv: white;
$border: #4a148c;

* { box-sizing: border-box; }
body {
    padding: 0px;
    margin: 0px;
    background: $bg;
    color: $fg;
}

/* code { font-size: larger; } */
pre code { font-size: medium; }

dl {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 1em;
    row-gap: 1ex;
}
dt { grid-column-start: 1; }
dd { grid-column-start: 2; }

div.highlight {
    background: $codebg;
    padding: 2px 20px;
    font-size: larger;
    border: solid 1px $border;
}

/* quesetionable */
div.nosep > div.highlight {
    padding: 0px 20px 0px 20px;
    margin: -10px 0px;
    border: none;
}

.right {
    float: left;
    width: 30%;
    min-height: 100vh;
    background: $standout;
    color: $fginv;
    font-weight: bold;
    padding: 0vw 2vw 0vw 2vw;
    border-left: solid 2px $border;
    border-bottom: solid 2px $border;
    border-radius: 0vw 0vw 0vw 2vw;

    ul {
        margin-left: 0px;
        padding: 0vw 0vw 0vw 2vw;
    }
    h1 { font-size: 4vw; }
}

.main {
    padding: 0vw 2vw 5vw 2vw;
    float: left;
    width: 70%;
}

@media screen and (max-width: 500px) {
    .main, .right { width: 100%; }

    /* Over the top, could kill the :before/:after and just put this in the .right:
        border-left: none;
        border-top: solid 2px $border;
    */
    .right {
        border: none;
        min-height: 0px;
        padding: 0vw 2vw 0vw 2vw;
    }
    .right:before, .right:after {
        content: "";
        height: 4px;
        background: linear-gradient(to right, $standout 0%, $border 50%, $standout 100%);
        display: block;
    }
}
